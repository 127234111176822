
import LoadableContentSpinner from 'components/Universal/LoadableContentSpinner';
import HeaderContext from 'providers/HeaderProvider';
import ThemeContext from 'providers/ThemeProvider';
import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';

const CoreTemplate = Loadable({
	loader: () => import('components/Templates/offer/CoreTemplate'),
	loading: LoadableContentSpinner
});

export default function calculatorOffer() {
	const { setHeader } = useContext(HeaderContext);
	const { setPrimaryColor } = useContext(ThemeContext);

	useEffect(() => {
		setHeader({ title: 'Oferta Limit', isLimit: true });
		setPrimaryColor('#25848D');
	}, []);

	return (
		<div>
			<CoreTemplate isLimitOffer />
		</div>
	);
}
