import React from 'react';
import { PulseLoader } from 'react-spinners';
import { val } from 'utils/helper';

export default function LoadableContentSpinner() {
	const spinnerColor = val('primary');

	return (
		<div className='w-full h-content flex items-center justify-center'>
			<PulseLoader color={spinnerColor} size={5} />
		</div>
	);
}
